<template>
    <div class="order-list">
        <section class="">
            <div class="row">
                <div class="col-lg-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import OrderTable from "@/components/order/OrderTable.vue"

export default {
    name: 'order-list',
    components:{
        OrderTable
    },
}
</script>
